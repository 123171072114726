<template>
  <div>
    <div class="about_page-main">
      <div id="container" style="width:100%;height:100vh;"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      map: null,
      dot: null,
      dotAnimation: null,
      docAnimationData: [],
      makerImg: require('../assets/img/map/maker.png')

    };
  },
  created() {
    const center = this.$route.query.center
    console.log("-> center", center);
    const that = this
    this.$nextTick(() => {
      that.initMap(center).then(val => {
        if (val) {
          that.initDotData()
          that.initMaker()
        }
      })
    })
  },
  methods: {
    initMap(center) {
      let lat = 37.203973
      let lng = 120.793906
      if (center === '1') {
        lat = 37.541819
        lng = 121.404685
      } else if (center === '2') {
        lat =  36.251227
        lng = 120.450883
      } else if (center === '3') {
        lat = 37.518677
        lng = 122.200805
      }
      const that = this
      // 初始化地图
      that.map = new that.$Map.Map('container', {
        center: new that.$Map.LatLng(lat, lng), //设置地图中心点坐标
        zoom: center ?  12: 10,
        mapStyleId: "style1", //个性化样式20
        baseMap: {
          type: "vector",
        },
        renderOptions: {
          enableBloom: true, // 泛光
        },
      })
      const mapContainer = document.getElementById("container")
      const logDiv = mapContainer.querySelector("div")
      const divArr = logDiv.getElementsByTagName('div')
      const logoContainer = divArr[1]
      logoContainer.style.display = "none"
      that.map.removeControl(that.$Map.constants.DEFAULT_CONTROL_ID.ZOOM)
      that.map.removeControl(that.$Map.constants.DEFAULT_CONTROL_ID.SCALE)
      that.map.removeControl(that.$Map.constants.DEFAULT_CONTROL_ID.ROTATION)
      return Promise.resolve(that.map)
    },
    initDot() {
      const that = this
      that.dot = new that.$Map.visualization.Dot({
        faceTo: "map", //散点固定的朝向
        styles: {
          style1: {
            fillColor: "#409eff",
            radius: 3,
          },
          style2: {
            fillColor: "#67c23a",
            radius: 3,
          },
        },
        enableBloom: true
      }).addTo(that.map).setData(that.docAnimationData)

      that.dotAnimation = new that.$Map.visualization.Dot({
        faceTo: "map", //散点固定的朝向
        styles: {
          style1: {
            type: "circle", //圆形样式
            fillColor: "rgba(64,158,255,0.3)",
            radius: 10,
          },
          style2: {
            type: "circle", //圆形样式
            fillColor: "rgba(103,194,58,0.3)",
            radius: 10,
          },
        },
        processAnimation: {
          animationType: "radiated", //动画类型
          enableFade: true, //动画类型
        },
      }).addTo(that.map).setData(that.docAnimationData)
    },
    initDotData() {
      const that = this
      const locData = JSON.parse(localStorage.getItem('docAnimationData'))
      const instance = axios.create({
        timeout: 500000,
      });
      if (locData) {
        that.docAnimationData = locData
        that.initDot()
      } else {
        instance.get('https://wx.jiashengyyds.com/jiasStoreInfo/jiasStoreInfo/findLocation', {headers: {'Content-Type': 'application/json'}}).then(res => {
          console.log('res!', res)
          const resData = res.data.data
          resData.forEach((item) => {
            const dotData = {
              lat: Number(item.latitude),
              lng: Number(item.longitude),
              styleId: item.zmId === 1 || item.zmId === 2 ? "style1" : "style2"
            }
            that.docAnimationData.push(dotData)
          })
          console.log(resData.length)
          const setData = JSON.stringify(that.docAnimationData)
          localStorage.setItem('docAnimationData', setData)
          that.initDot()
        })
      }
    },
    destroyMap() {
      this.map.destroy()
      this.map = null
      this.dot = null
    },
    initMaker() {
      const that = this
      that.marker = new that.$Map.MultiMarker({
        map: that.map,
        styles: {
          "myStyle": new that.$Map.MarkerStyle({
            "width": 15,
            "height": 15,
            "src": that.makerImg,
          })
        },
        geometries: [{
          "id": "1",
          "styleId": 'myStyle',
          "position": new that.$Map.LatLng(37.547135, 121.256015),
          "properties": {
            "title": "烟台"
          }
        }, {
          "id": "2",
          "styleId": 'myStyle',
          "position": new that.$Map.LatLng(36.083022, 120.338743),
          "properties": {
            "title": "青岛"
          }
        }
        ]
      });
    }
  }
};
</script>

<style lang="scss">
</style>
